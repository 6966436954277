<template>
  <dashboard-component>
    <div v-if="loading">
      <h3>Loading...</h3>
      <spinner v-if="loading" :showOverlay="true"></spinner>
    </div>
    <div v-else>
    <h1 class="d-flex flex-row justify-content-center">{{ user.name }}</h1>
    <div class="container">
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Original Id</div>
        <div class="col-lg col-sm-9 p-2">{{ user.orig_id }}</div>
      </div>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Login</div>
        <div class="col-lg col-sm-9 p-2">{{ user.login }}</div>
      </div>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Name</div>
        <div class="col-lg col-sm-9 p-2">
          <input v-if="editMode" type="text" v-model="user.name" />
          <span v-else>{{ user.name }}</span>
        </div>
      </div>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Email</div>
        <div class="col-lg col-sm-9 p-2">
          <input v-if="editMode" type="text" v-model="user.email"/>
          <span v-else>{{ user.email }}</span>
        </div>
      </div>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Location</div>
        <div class="col-lg col-sm-9 p-2">{{ user.location_name }}</div>
      </div>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Role</div>
        <div class="col-lg col-sm-9 p-2">{{ user.role_name }}</div>
      </div>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Active</div>
        <div class="col-lg col-sm-9 p-2">
          <i class="material-icons">
            {{ user.is_active ? "check_box" : "check_box_outline_blank" }}
          </i>
        </div>
      </div>
      <div class="row">
        <div class="offset-lg-6 col-lg offset-sm-3">
          <spinner v-if="saving" :showOverlay="true"></spinner>
          <div class="btn-group" role="group">
            <button v-if="!editMode" class="btn" :class="emailBtn.btnClass" :disabled="emailBtn.disabled" @click="sendEmail">{{ emailBtn.text }}</button>
            <template v-if="canEdit">
            <button v-if="!editMode" class="btn btn-primary" @click="editMode = true">Edit</button>
            <button v-if="editMode" class="btn btn-primary" @click="saveUser">Save</button>
            <button v-if="editMode" class="btn btn-danger" @click="cancelSave">Cancel</button>
            </template>
          </div>
        </div>
      </div>
    </div>
    </div>
  </dashboard-component>
</template>
<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  props:['login'],
  emits:[],
  setup(props, { emit }){
    const router = useRouter()
    const store = useStore()
    const login = props.login
    const user = ref({})
    const userOrig = ref({})
    const editMode = ref(false)
    const emailSent = ref(false)
    const loading = ref(true)
    const saving = ref(false)
    const canEdit = computed(() => {
        let role = store.state.userData?.user?.role
        if(role?.is_admin || role?.write_user)
        {
          return true
        }
        return false
      }
    )
    const emailState = {
      initial:{ btnClass:"btn-primary",text:"Email",disabled:false},
      sent:{ btnClass:"btn-success",text:"Sent",disabled:true},
      error:{ btnClass:"btn-danger",text:"Error",disabled:false},
    }
    
    const emailBtn = ref(emailState.initial)
    const getUser = async () => {
      loading.value = true;
      try {
        let { data } = await axios.get('/api/users/' + login)
        Object.assign(user.value,data)
        Object.assign(userOrig.value,data)
      } catch (err) {
        if(err.response && err.response.status == 404)
        {
          router.push({'name':'NotFoundAll'})
        }
      }
      loading.value = false;
    }
    const saveUser = async () => {
      saving.value = true;
      try {
        let updates = {
          'name': user.value.name,
          'email':user.value.email
        }
        let { data } = await axios.patch('/api/users/' + login, updates)
        Object.assign(user.value,data)
        Object.assign(userOrig.value,data)
        editMode.value = false
        
      } catch (err) {
        /* handle error */
        console.error(err)
      }
      saving.value = false;
    }
    const sendEmail = async () => {
      saving.value = true;
      if(emailBtn.value === emailState.error)
      {
        emailBtn.value = emailState.initial
        return
      }
      emailBtn.value = emailState.initial
      emailBtn.value.disabled = true
      try {
        await axios.post('/api/users/' + login + '/email')
        emailBtn.value = emailState.sent
      } catch (err) {
        /* handle error */
        console.error(err)
        emailBtn.value = emailState.error
      }
      saving.value = false;
    }
    const cancelSave = () => {
      user.value.name = userOrig.value.name
      user.value.email = userOrig.value.email
      editMode.value = false
    }
    getUser()
    return {
      user,
      editMode,
      canEdit,
      getUser,
      saveUser,
      cancelSave,
      sendEmail,
      emailSent,
      emailBtn,
      loading,
      saving,
    }
  }
}
</script>
<style scoped>
  .btn {
    font-weight:bold;
    color:white;
  }
</style>
