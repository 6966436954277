<template>
  <div class="d-flex flex-row align-items-center justify-content-center" 
    style="top:0;bottom: 0; left: 0; right: 0; position: absolute">
    <div class="container">
      <div class="row">
        <div class="offset-sm-2 col-sm-8">
          <div class="card align-self-center flex-grow-1">
            <div class="card-header text-center" style="font-size: x-large">
              Profile Review
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                  <label for="username">Username</label>
                  <input id="username" v-model="username" type="text" 
                    class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                  <label for="password">Password</label>
                  <input id="password" v-model="password" type="password" 
                    class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                  <button v-if="false" class="btn btn-link text-success p-0">
                    Forgot Password?
                  </button>&nbsp;
                </div>
              </div>
              <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                  <button class="btn btn-success w-100" 
                    v-on:click="authenticate" v-bind:disabled="loading">
                    <spinner v-if="loading" :showOverlay="false"></spinner>
                    <span v-else>Login</span>&nbsp;
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="offset-sm-2 col-sm-8">
                  <span class="text-danger" v-if="loginFailed">
                    Login Failed. Please check your credentials.
                  </span>&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data:()=>{
    return {
      username:"",
      password:"",
      loading: false,
      loginFailed: false,
    }
  },
  computed: mapState(['userData']),
  methods: {
    ...mapActions(['login']),
    authenticate() {
      this.loginFailed = false;
      var credentials = {
        username:this.username,
        password:this.password,
      };
      this.loading = true;
      this.login(credentials)
        .then(_ => {
            this.loading = false;
          }
        )
        .catch(error => {
          this.loading = false;
          this.loginFailed = true;
          console.error(error);
        }
      );
    }
  }
}
  
</script>
