<template>
  <dashboard-component>
    <h1>Report</h1>
    <div v-if="loading">
      <h3>Loading...</h3>
      <spinner v-if="loading" :showOverlay="true"></spinner>
    </div>
    <div v-else>
    <profile-locations-component v-model="locationFilter" @on-change="getReport"/>
    <h3>Report Date: {{ new Date().toLocaleString() }}</h3>
    <h3>Report Location: {{ report.report_location }}</h3>
    <table class="table">
      <thead>
        <tr>
          <th>Location</th>
          <th style="text-align:right">Total Students</th>
          <th style="text-align:right">Verified By Teacher</th>
          <th style="text-align:right">Verified By Student</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="loc in report.locations" :key="loc.location">
          <td> {{ loc.location }} </td>
          <td style="text-align:right"> {{ Number(loc.students).toLocaleString() }} </td>
          <td style="text-align:right"> {{ Number(loc.teacher_verify).toLocaleString() }} </td>
          <td style="text-align:right"> {{ Number(loc.student_verify).toLocaleString() }} </td>
        </tr>
        <tr>
          <td><strong> Total: </strong></td>
          <td style="text-align:right"><strong> {{ Number(report.total_students).toLocaleString() }} </strong></td>
          <td style="text-align:right"><strong> {{ Number(report.total_teacher_verify).toLocaleString() }} </strong></td>
          <td style="text-align:right"> <strong> {{ Number(report.total_student_verify).toLocaleString() }} </strong></td>
        </tr>
      </tbody>
    </table>
    </div>
  </dashboard-component>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
  setup(){
    const store = useStore();
    const router = useRouter();
    const report = ref({})
    const loading = ref(true)
    const locationFilter = ref(-1)
    const filterCount = ref(0)
    
    const userData = computed(() => store.state.userData);
    const user = computed(() => {

          if (userData.value != null)
          {
            return userData.value.user;
          }
          return null;
    });

    const url = ()=> {
      var base = '/api/profiles/report'
      filterCount.value = 0
      if(locationFilter.value != -1)
      {
        base = base + '?location=' + locationFilter.value
        filterCount.value++
      }
      return base
    }
    const getReport = async () => {
      loading.value = true;
      try {
        
        let { data } = await axios.get(url())
        
        console.log(data)
        report.value = data.report;
      }
      catch(err)
      {
        console.error(err);
      }
      loading.value = false;
    }

    getReport();

    return {
      getReport,
      loading,
      locationFilter,
      userData,
      user,
      report,
    }
  }
}
</script>
<style>
  
</style>
