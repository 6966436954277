<template>
<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container">
    <router-link class="navbar-brand" to="/">
      <img src="/images/isn-logo.svg" width="30" height="30" class="d-inline-block align-top" alt="">
      Ismaili Student Network Registration
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" 
      data-bs-target="#navbarNav" aria-controls="navbarNav" 
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div v-if="user" class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav me-auto">
      </ul>
      <ul class="navbar-nav" v-if="user">
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name:'AllProfiles'}">
            Profiles
          </router-link>
        </li>
        <li class="nav-item" v-if="user.role.is_admin || user.role.write_user || user.role.read_user">
          <router-link class="nav-link" v-if="user" :to="{name: 'Users'}">
            Users
          </router-link>
        </li>
        <li class="nav-item" v-if="user.role.is_admin">
          <router-link class="nav-link" v-if="user" :to="{name: 'Roles'}">
            Roles
          </router-link>
        </li>
        <li class="nav-item" v-if="user.role.is_admin">
          <router-link class="nav-link" v-if="user" :to="{name: 'Locations'}">
            Locations
          </router-link>
        </li>
        <li class="nav-item" v-if="user.role.is_admin">
          <router-link class="nav-link" v-if="user" :to="{name: 'Configs'}">
            Configs
          </router-link>
        </li>
        <li class="nav-item" v-if="user.role.is_admin || user.role.report">
          <router-link class="nav-link" v-if="user" :to="{name: 'Reports'}">
            Reports
          </router-link>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link class="nav-link" v-if="user" :to="{name: 'ShowUser',params:{login:user.login} }">
          {{ user.name }}
          </router-link>
        </li>
        <li class="nav-item">
          <a v-if="user" class="nav-link" href="#" @click="logout">Logout</a>
          <router-link v-else class="nav-link" :to="{name:'Login'}">
            Login
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container mt-4">
  <slot>
    <h1>Home</h1>
  </slot>
</div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup(){
    const store = useStore();
    const router = useRouter();

    // data
    // computed
    const userData = computed(() => store.state.userData);
    const user = computed(() => {

          if (userData.value != null)
          {
            return userData.value.user;
          }
          return null;
    });

    // methods
    const clearUserData = () => store.commit('clearUserData');
    const logout = () => {
      clearUserData();
      router.push({name:"Login"});
    }
    return {
      userData,
      user,
      clearUserData,
      logout
    }
  }
}
</script>
<style scoped>
.navbar {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
