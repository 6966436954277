<template>
  <dashboard-component v-if="user">
    <h1>All Profiles</h1>
      <h3>Welcome {{ user.name }} (<span v-if="user.location">{{ user.location.name }}</span> {{ user.role.name }}) </h3>
      <div v-if="user.role.write_profile">
        <ol>
          <li>Please make any updates necessary.  To update student information, please click on student name</li>
          <li>Please make sure that we have correct contact information of the student and not their parents</li>
          <li>Please click on the Save icon when done.  You will be able to update the information until the student verifies the information</li>
          <li>Teacher Verified = <i class="material-icons">done</i></li>
          <li>Teacher and Student Verified = <i class="material-icons">done_all</i></li>
        </ol>
      </div>
    <pager :currentPageNo="currentPageNo" :lastPageNo="lastPageNo" @first-page="firstPage" @prev-page="prevPage" @next-page="nextPage" @last-page="lastPage" :key="pageKey"></pager>
    <div v-if="loading">
      <h3>Loading...</h3>
      <spinner v-if="loading" :showOverlay="true"></spinner>
    </div>
    <div v-else>
    <profile-locations-component v-model="locationFilter" @on-change="firstPage"/>
    <table class="table">
      <colgroup>
        <col style="width: 20%" />
        <col style="width: 50%" />
        <col style="width: 15%" />
        <col style="width: 15%;min-width: 100px" />
      </colgroup>
      <thead>
        <tr>
          <th>Student Name</th>
          <th>Student Email</th>
          <th>Student Phone</th>
          <th>
            <select class="form-select" v-model="statusFilter" @change="firstPage">
              <optgroup label="Status">
              <option value="">All</option>
              <option value="0">Not verified</option>
              <option value="1">Verified By Teacher</option>
              <option value="2">Verified By Student</option>
              <option value="-1">Unable to verify</option>
              </optgroup>
            </select>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="items in profiles.data" :key="items.location">
        <tr>
          <td colspan="4"><strong>{{items.location}}</strong></td>
        </tr>
        <show-edit-profile-component v-for="profile in items.profiles" :key="profile.id" :profile="profile"/>
        </template>
      </tbody>
    </table>
    </div>
  </dashboard-component>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
  setup(){
    const store = useStore();
    const router = useRouter();
    const profiles = ref({})
    const pageKey = ref([-1,-1])
    const loading = ref(true)
    const locationFilter = ref(-1)
    const statusFilter = ref("")
    const filterCount = ref(0)
    
    const userData = computed(() => store.state.userData);
    const user = computed(() => {

          if (userData.value != null)
          {
            return userData.value.user;
          }
          return null;
    });

    const url = (page)=> {
      var base = '/api/profiles'
      filterCount.value = 0
      if(locationFilter.value != -1)
      {
        base = base + '?location=' + locationFilter.value
        filterCount.value++
      }
      if(statusFilter.value != "")
      {
        base = base + (filterCount.value == 0 ? '?':'&') + 'status=' + statusFilter.value
        filterCount.value++
      }
      base = base + (filterCount.value == 0 ? '?':'&') + 'page=' + page
      return base
    }
    const getProfiles = async (page) => {
      loading.value = true;
      try {
        
        let { data } = await axios.get(url(page))
        data.data = data.data.map(d => {d.edit = false; return d})

        data.data = data.data.reduce((grp,profile) => {
          let loc_group = grp.find(g => g.location == profile.location_name)
          if(!loc_group)
          {
            loc_group = { location: profile.location_name, profiles: []}
            grp.push(loc_group)
          }
          loc_group.profiles.push(profile)
          loading.value = false;
          return grp
        },[]);
        console.log(data)
        profiles.value = data
         
        pageKey.value = [profiles.value.current_page,profiles.value.last_page];
      }
      catch(err)
      {
        console.error(err);
      }
      loading.value = false;
    }
    const firstPage = () => getProfiles(1);
    firstPage();
    const nextPage = () => getProfiles(profiles.value.current_page + 1);
    const prevPage = () => getProfiles(profiles.value.current_page - 1);
    const lastPage = () => getProfiles(profiles.value.last_page);
    const hasPages = computed(() => profiles.value && profiles.value.last_page > 1);
    const currentPageNo = computed(() => profiles.value.current_page);
    const lastPageNo = computed(() => profiles.value.last_page);
    return {
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      profiles,
      hasPages,
      currentPageNo,
      lastPageNo,
      pageKey,
      loading,
      locationFilter,
      statusFilter,
      userData,
      user,
    }
  }
}
</script>
<style>
  
</style>
