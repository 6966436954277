<template>
  <select class="form-select" v-if="editMode" v-model="modelValue"  @change="valueChanged">
    <option></option>
    <option v-for="location in notDecendent" :key="location.id" 
      :value="location.id">
      {{location.formatted}}
    </option>
  </select>
  <span v-if="!editMode">{{ modelText }}</span>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  props:['modelValue','editMode','exclude'],
  emits:['update:modelValue'],
  setup(props,{emit}){
    const store = useStore();
    const exclude = computed(() => props.exclude ? locations.value.find((location) => location.id === props.exclude.id):null);
    const modelValue = props.modelValue;
    const locations = computed(() => store.state.locationsFormatted)
    const model = computed(() => modelValue ? locations.value.find(loc => loc.id === modelValue) : null)
    const valueChanged = (e) => {
      emit('update:modelValue',e.target.value);
    }
    
    const notDecendent = computed(()=>{
      if(!exclude || !exclude.value)
      {
        return locations.value;
      }
      else
      {
        return locations.value.filter((location)=>{
          return location.id != exclude.value.id &&
            !exclude.value.decendents.includes(location.id)
        });
      }
    })  
    const modelText = computed(() => {
      return model.value ? model.value.formatted : "";
    })
    return {
      locations,
      valueChanged,
      modelText,
      notDecendent,
    }
  }
}
</script>
<style scoped>
</style>
