<template>
  <router-view></router-view>
</template>
<script>

import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup(){
    let router = useRouter()

    let sessionData = window.sessionStorage.getItem('vuex')
    if(sessionData)
    {
      let jsSessionData = JSON.parse(sessionData)
      let token = jsSessionData?.userData?.token
      if(token)
      {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      }
      else
      {
        axios.defaults.headers.common['Authorization'] = null
      }
    }
  
    axios.interceptors.response.use(
      response => response,
      error => {
        console.error(error);
        if (error.response.status === 401){
          console.error('401: Logging Out')
          window.sessionStorage.clear()
          router.push({name:'Login'})
        }
        return Promise.reject(error)
      }
    )
    return {
    }
  }
}
</script>
