<template>
  <edit-profile-component :profile="profile" v-if="profile.edit"/>
  <show-profile-component :profile="profile" v-else/>
</template>
<script>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  props:['profile'],
  emits:[],
  setup(props, { emit }){
    const profile = computed(() => props.profile)

    return {
      profile
    }
  }
}
</script>
<style scoped>
</style>
