require('./bootstrap');

import { createApp } from 'vue'
import router from './router'
import { store } from './store'

//Main pages
import App from './views/AppComponent.vue'

window.getBaseURL = function (url) {
    return url.protocol + "//" + url.hostname + ":" + url.port;
}

window.validateEmail = function (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
window.validatePhone = function (phone) {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(String(phone).toLowerCase());
}
const app = createApp(App)
const files = require.context('.', true, /\.vue$/i)
files.keys().map(key => app.component(key.split('/').pop().split('.')[0], files(key).default))
app.use(router)
app.use(store)
app.mount("#app")

