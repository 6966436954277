<template>
  <div v-if="hasPages" class="btn-toolbar" role="toolbar">
    <div class="btn-group" role="group">
      <button class="btn btn-primary" :disabled="currentPageNo <= 1" @click="firstPage"><i class="material-icons mt-1">skip_previous</i></button>
      <button class="btn btn-primary" :disabled="currentPageNo <= 1" @click="prevPage"><i class="material-icons mt-1">fast_rewind</i></button>
    </div>
    <div class="my-auto mx-3">
      <span>Page {{ currentPageNo }} of {{ lastPageNo }}</span>
    </div>
    <div class="btn-group" role="group">
      <button class="btn btn-primary" :disabled="currentPageNo >= lastPageNo" @click="nextPage"><i class="material-icons mt-1">fast_forward</i></button>
      <button class="btn btn-primary" :disabled="currentPageNo >= lastPageNo" @click="lastPage"><i class="material-icons mt-1">skip_next</i></button>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue'
export default {
  props:['currentPageNo','lastPageNo'],
  emits:['first-page','prev-page','next-page','last-page'],
  setup(props, { emit }){
    const currentPageNo = ref(props.currentPageNo)
    const lastPageNo = ref(props.lastPageNo)
    const hasPages = computed(() => lastPageNo.value > 1)
    const firstPage = () => emit('first-page')
    const prevPage = () => emit('prev-page')
    const nextPage = () => emit('next-page')
    const lastPage = () => emit('last-page')
    return {
      currentPageNo,
      lastPageNo,
      hasPages,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
    }
  }
}
</script>
<style scoped>
</style>
