<template>
  <dashboard-component>
    <button v-if="!addMode && (user.role.is_admin || user.role.write_user)" class="btn btn-primary" @click="addMode = true">Add User</button>
    <div v-if="addMode">
      <h1>Add User</h1>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Name</div>
        <div class="col-lg col-sm-9 p-2">
          <input type="text" class="form-control" v-model="name" />
        </div>
      </div>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Email</div>
        <div class="col-lg col-sm-9 p-2">
          <input type="text" class="form-control" v-model="email"/>
        </div>
      </div>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Password</div>
        <div class="col-lg col-sm-9 p-2">
          <input type="password" class="form-control" v-model="password"/>
        </div>
      </div>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Location</div>
        <div class="col-lg col-sm-9 p-2">
          <select v-model="loc_id" >
          <option v-for="location in profileLocations" :value="location.id" :key="location.id">{{location.name}}</option>
        </select>
        </div>
      </div>
      <div class="row">
        <div class="offset-lg-4 col-lg-2 col-sm-3 p-2">Role</div>
        <div class="col-lg col-sm-9 p-2">
          <select v-model="role_id" >
          <option v-for="role in roles" :key="role.id" 
            :value="role.id">
            {{role.name}}
          </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="offset-lg-6 col-lg offset-sm-3">
          <spinner v-if="saving" :showOverlay="true"></spinner>
          <div class="btn-group" role="group">
            <button class="btn btn-primary" @click="saveUser">Save</button>
            <button class="btn btn-danger" @click="addMode = false">Cancel</button>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="offset-sm-2 col-sm-8">
            <span class="text-danger" v-if="showError">
              Add User Failed.  Check all fields. 
            </span>&nbsp;
          </div>
      </div>
    </div>
    <div v-else>
    <h1>All Users</h1>
    <pager :currentPageNo="currentPageNo" :lastPageNo="lastPageNo" @first-page="firstPage" @prev-page="prevPage" @next-page="nextPage" @last-page="lastPage" :key="pageKey"/>
    <div v-if="loading">
      <h3>Loading...</h3>
      <spinner v-if="loading" :showOverlay="true"></spinner>
    </div>
    <div v-else>
    <profile-locations-component v-model="locationFilter" @on-change="firstPage"/>
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Location</th>
          <th>
            <select class="form-select" v-model="roleFilter" @change="firstPage">
              <option value="-1">All</option>
              <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
            </select>
          </th>
          <th>Active</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users.data" :key="user.id">
          <td>
            <router-link :to="{ name: 'ShowUser', params:{ login:user.login} }">
            {{ user.name }}
            </router-link>
          </td>
          <td>{{user.email}}</td>
          <td>{{user.location_name}}</td>
          <td>{{user.role_name}}</td>
          <td>
            <i class="material-icons">{{user.is_active ? "check_box" : "check_box_outline_blank"}}</i>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    </div>
    </div>
  </dashboard-component>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  props:[],
  emits:[],
  setup(props, { emit }){
    const store = useStore();
    const router = useRouter();
    const userData = computed(() => store.state.userData);
    const user = computed(() => {

          if (userData.value != null)
          {
            return userData.value.user;
          }
          return null;
    });
    const roles = ref([]);
    const users = ref({});
    const pageKey = ref(0);
    const addMode = ref(false);
    const name = ref("");
    const email = ref("");
    const loc_id = ref(-1);
    const profileLocations = computed(() => store.state.profileLocations)
    const role_id = ref(null);
    const password = ref("");
    const showError = ref(false);
    const loading = ref(true);
    const saving = ref(false);
    
    const getRoles = async () => {
      try{
        roles.value = [];
        store.state.roles.forEach(r => {
          if(user.value.role.is_admin || !r.is_admin)
          {
            roles.value.push(r);
          }
        });
      }
      catch(err)
      {
        console.error(err);
      }
    }

    const saveUser = async () => {
      saving.value = true;
      try {
        let updates = {
          'name': name.value,
          'email': email.value,
          'password': password.value,
          'loc_id': loc_id.value,
          'role_id': role_id.value,
        }
        let { data } = await axios.post('/api/users/add', updates);
        router.push({name: "ShowUser", params:{ login:data.login} });
      } catch (err) {
        /* handle error */
        console.log(err)
        showError.value = true;
      }
      saving.value = false;
    }

    const filterCount = ref(0);
    const locationFilter = ref(-1);
    const roleFilter = ref(-1);

    const url = (page) => {
      var base = '/api/users'
      filterCount.value = 0

      if(locationFilter.value != -1)
      {
        base = base + '?location=' + locationFilter.value
        filterCount.value++
      }
      if(roleFilter.value != -1)
      {
        base = base + (filterCount.value == 0 ? '?':'&') + 'role=' + roleFilter.value
        filterCount.value++
      }
      base = base + (filterCount.value == 0 ? '?':'&') + 'page=' + page
      return base
    }

    const getUsers = async (page) => {
      loading.value = true;
      try {
        let { data } = await axios.get(url(page));
        users.value = data;
        pageKey.value = [users.value.current_page,users.value.last_page];

      } catch (err) {
        /* handle error */
        console.error(err);
      }
      loading.value = false;
    }

    getRoles();
    const firstPage = () => getUsers(1);
    firstPage();
    const prevPage = () => getUsers(users.value.current_page - 1);
    const nextPage = () => getUsers(users.value.current_page + 1);
    const lastPage = () => getUsers(users.value.last_page);
    const currentPageNo = computed(() => users.value.current_page);
    const lastPageNo = computed(() => users.value.last_page);

    return {
      firstPage,
      lastPage,
      prevPage,
      nextPage,
      pageKey,
      currentPageNo,
      lastPageNo,
      users,
      addMode,
      name,
      email,
      password,
      loc_id,
      roles,
      role_id,
      saveUser,
      showError,
      loading,
      saving,
      user,
      profileLocations,
      locationFilter,
      roleFilter,
    }
  }
}
</script>
<style scoped>
</style>
