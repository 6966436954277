<template>
  <dashboard-component>
    <h1>Locations</h1>
    <div v-if="loading">
      <h3>Loading...</h3>
      <spinner v-if="loading" :showOverlay="true"></spinner>
    </div>
    <div v-else>
    <table class="table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Parent</th>
          <th>Orig Id</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <new-location-component @location-created="getLocations"/>
        </tr>
        <tr v-for="loc in locations" :key="loc.id">
          <show-location-component v-if="!loc.edit" :loc="loc"
            @location-deleted="getLocations"/>
          <edit-location-component v-else :loc="loc" />
        </tr>
      </tbody>
    </table>
    </div>
  </dashboard-component>
</template>
<script>
import { ref, computed, onMounted  } from 'vue'
import { useStore } from 'vuex'
export default {
  setup(){
    const loading = ref(true);
    const store = useStore();
    const locations = computed(() => store.state.locationsFormatted );
    const getLocations = () => {
      loading.value = true;
      store.dispatch('getLocations').then(function() {
        loading.value = false;
      });
    }
    
    onMounted(() => {
      getLocations();
    });
    
    return {
      locations,
      getLocations,
      loading,
    }
  }
}
</script>
<style scoped>
</style>
