<template>
<spinner v-if="saving" :showOverlay="true"></spinner>
  <td>{{loc.id}}</td>
  <td colspan="2">{{loc.formatted}}</td>
  <td>{{loc.orig_id}}</td>
  <td>
    <a href="#" @click.prevent="toggle">
      <i class="material-icons">create</i>
    </a>
    &nbsp;
    <a href="#" @click.prevent="deleteLocation">
      <i class="material-icons">delete</i>
    </a>
  </td>
</template>
<script>
import { ref } from 'vue'
export default {
  props:['loc'],
  emits:['location-deleted'],
  setup(props, {emit}){
    const saving = ref(false);
    const loc = props.loc;
    const toggle = () => loc.edit = !loc.edit;
    const deleteLocation = async () => {
      try{
        saving.value = true;
        let resp = await axios.delete('/api/locations/' + loc.id);
        saving.value = false;
        emit('location-deleted');
      }
      catch(err)
      {
        console.error(err);
      }
      saving.value = false;
    }
    return {
      toggle,
      deleteLocation,
      saving,
    }
  }
}
</script>
<style scoped>
</style>
