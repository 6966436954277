<template>
<spinner v-if="saving" :showOverlay="true"></spinner>
  <tr>
    <td>
      {{ profile.first_name }} {{ profile.middle_name }} {{ profile.last_name }}
    </td>
    <td>
      <input class="form-control" type="text" v-model="profile.email_updated">
    </td>
    <td>
      <input class="form-control" type="text" v-model="profile.phone_updated">
    </td>
    <td class="text-center">
      <a href="#" @click.prevent="saveProfile"><i class="material-icons">save</i></a>
      <a href="#" @click.prevent="profile.edit = false"><i class="material-icons">cancel</i></a>
    </td>
  </tr>
</template>
<script>
import { ref, computed } from 'vue'
export default {
  props:['profile'],
  emits:['changes-submited'],
  setup(props, { emit }){
    const saving = ref(false);
    const profile = ref(props.profile);
    if(!profile.value.email_updated || profile.value.email_updated.length == 0)
    {
      profile.value.email_updated = profile.value.email_orig;
    }
    if(!profile.value.phone_updated || profile.value.phone_updated.length == 0)
    {
      profile.value.phone_updated = profile.value.phone_orig;
    }
    const validateInfo = () => {
      if (!validateEmail(profile.value.email_updated))
      {
        alert('Please enter a valid email address');
        return false;
      }
      if (profile.value.phone_updated && profile.value.phone_updated.length > 0 && !validatePhone(profile.value.phone_updated))
      {
        alert('Please enter a valid 10 digit phone number');
        return false;
      }
      return true;
    }
    const saveProfile = async () => {
      if(!validateInfo())
      {
        return;
      }
      saving.value = true;
      let updates = {
        email:profile.value.email_updated,
        phone:profile.value.phone_updated
      }
      try{
        let { data } = await axios.patch('/api/profiles/' + profile.value.id, updates);
        profile.value.edit = false;
        profile.value.status_id = data.status_id;
        emit('changes-submited')

      }
      catch(err)
      {
        console.error(err);
      }
      saving.value = false;
    }
    return {
      profile,
      saveProfile,
      saving,
    }
  }
}
</script>
<style scoped>
</style>
