<template>
  <dashboard v-if="userData != null" :user="userData.user"/>
  <login v-else/>
</template>
<script>

import { mapState, mapMutations } from 'vuex'

export default {
  created() {
    const storedUserData = localStorage.getItem('userData');
    if(storedUserData){
      const userData = JSON.parse(storedUserData);
      this.setUserData(userData);
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        console.error(error);
        if (error.response.status === 401){
          console.error('401: Logging Out');
          this.clearUserData();
        }
        return Promise.reject(error);
      }
    );
  },
  computed: mapState(['userData']),
  methods:{
    ...mapMutations(['setUserData','clearUserData']),
    logout(){
      this.clearUserData();
    }
  }
};
</script>
