import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export const store = createStore({
  plugins: [createPersistedState(
    {storage: window.sessionStorage}
  )],
  state: () => {
    return {
      userData: null,
      roles:[],
      locations: [],
      profileLocations: [],
      locationsFormatted: [],
    }
  },
  getters: {
    role: (state) => {
      if (state.userData && state.userData.user) {
        return state.userData.user.role
      }

      return null
    },
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
      axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
    },
    clearUserData(state) {
      axios.defaults.headers.common['Authorization'] = null;
      state.userData = null
      window.sessionStorage.clear()
    },
    setLocations(state, locations) {
      state.locations = locations;
    },
    setLocationsFormatted(state, locationsFormatted) {
      state.locationsFormatted = locationsFormatted
    },
    setProfileLocations(state, locations) {
      state.profileLocations = []
      state.profileLocations = locations
    },
    clearLocations(state) {
      state.locations = []
      state.locationsFormatted = []
    },
    setRoles(state, roles) {
      state.roles = []
      state.roles = roles
    },
    clearRoles(state) {
      state.roles = []
    }
  },
  actions: {
    login({ commit, dispatch }, credentials) {
      let trylogin;
      if (credentials.login && credentials.login.length > 0) {
        trylogin = axios.post('/api/login/' + credentials.login)
      }
      else {
        trylogin = axios.post('/api/login', credentials)
      }
      return trylogin.then(({ data }) => {
        commit('setUserData', data)
        dispatch('getProfileLocations')
        dispatch('getRoles')
        dispatch('getLocations')
        return Promise.resolve()
      })
        .catch(reason => {
          console.error('Login Failed')
          return Promise.reject(reason)
        })
    },
    logout({ commit }) {
      commit('clearUserData');
      console.log("Logged out");
    },
    getRoles({ commit }) {
      commit('clearRoles')
      return axios.get('/api/roles')
        .then(({ data }) => {
          commit('setRoles',data)
          return Promise.resolve()
        }
      ).catch(err => {
        console.error(err)
        return Promise.reject(err)
      })
    },
    getLocations({ commit }) {
      commit('clearLocations');
      return axios.get('/api/locations')
        .then(({ data }) => {
          let formatted = [];
          for (let i = 0; i < data.length; i++) {
            formatted.push({
              id: data[i].id, name: data[i].name, orig_id: data[i].orig_id,
              parent_loc_id: data[i].parent_loc_id, formatted: data[i].name,
              decendents: [], parent_id: data[i].parent_loc_id,
            });
          }

          const getParent = (location) => {
            return location.parent_id ?
              formatted.find(parent => parent.id === location.parent_id)
              : null;
          }

          // loop for decendents
          for (let i = 0; i < formatted.length; i++) {
            let current = formatted[i];
            let parent = current;
            while (parent = getParent(parent)) {
              parent.decendents.push(current.id);
            }
          }
          // loop for name
          let done = false;
          while (!done) {
            let modified = false;
            for (let i = 0; i < formatted.length; i++) {
              let current = formatted[i];
              if (current.parent_id != null) {
                let parent = formatted.find(loc => loc.id === current.parent_id);
                current.formatted = parent.formatted + " > " + current.formatted;
                current.parent_id = parent.parent_id;
                modified = true;
              }
            }
            done = !modified;
          }
          data.sort((a, b) => {
            if (formatted.find(l => l.id === a.id).formatted < formatted.find(l => l.id === b.id).formatted)
              return -1;
            else
              return 1;
          });
          formatted.sort((a, b) => {
            if (a.formatted < b.formatted)
              return -1;
            else
              return 1;
          });
          commit('setLocations', data);
          commit('setLocationsFormatted', formatted);
          return Promise.resolve(data);
        })
        .catch(err => {
          console.error(err);
          return Promise.reject(err);
        }
        );
    },
    async getProfileLocations({ commit }) {
      try {
        let { data } = await axios.get('/api/profiles/locations');
        let loclist = data.locations;
        loclist.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        commit('setProfileLocations', loclist);
      }
      catch (err) {
        console.error(err);
      }
    }
  }
})
