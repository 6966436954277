<template>
  <tr :class="{ hasStatusComments : profile.status_id == -1 }">
    <td>
      <a v-if="profile.status_id < 2" 
        href="#" @click.prevent="clickHandler" >
      {{ profile.first_name }} {{ profile.middle_name }} {{ profile.last_name }}
      </a>
      <span v-else>
      {{ profile.first_name }} {{ profile.middle_name }} {{ profile.last_name }}
      </span>
    </td>
    <td>{{ email }}</td>
    <td>{{ phone }}</td>
    <td class="text-center">
      <i class="material-icons" v-if="profile.status_id == -1">indeterminate_check_box</i>
      <i class="material-icons" v-if="profile.status_id == 0">check_box_outline_blank</i>
      <i class="material-icons" v-if="profile.status_id == 1">done</i>
      <i class="material-icons" v-if="profile.status_id == 2">done_all</i>
    </td>
  </tr>
  <tr v-if="profile.status_id == -1" class="statusComments">
    <td></td>
    <td colspan="2">{{ profile.status_comments }}</td>
    <td></td>
  </tr>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  props:['profile'],
  emits:[],
  setup(props, { emit }){
    const store = useStore()
    const allowEdit = computed(() => {
      if(store.getters.role)
      {
        const role = store.getters.role
        return role.is_admin || role.write_profile
      }
      return false
    })
    const profile = computed(() => props.profile);
    const email = computed(() => {if(profile.value.email_updated && profile.value.email_updated.length > 0) { return profile.value.email_updated } else { return profile.value.email_orig}});
    const phone = computed(() => {if(profile.value.phone_updated && profile.value.phone_updated.length > 0) { return profile.value.phone_updated } else { return profile.value.phone_orig}});
    const clickHandler = ()=> {if(allowEdit.value && profile.value.status_id != 2) {profile.value.edit = true}}
    return {
      profile,
      email,
      phone,
      clickHandler,
    }
  }
}
</script>
<style scoped>
.hasStatusComments > td {
  border-bottom-style:none;
}
.statusComments > td {
  border-top-style:none;
}
</style>
