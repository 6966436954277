<template>
<spinner v-if="saving" :showOverlay="true"></spinner>
  <td><input type="number" v-model="role.id"/></td>
  <td><input type="text" v-model="role.name"/></td>
  <td><input type="checkbox" v-model="role.is_admin"/></td>
  <td><input type="checkbox" v-model="role.read_user"/></td>
  <td><input type="checkbox" v-model="role.write_user"/></td>
  <td><input type="checkbox" v-model="role.read_profile"/></td>
  <td><input type="checkbox" v-model="role.write_profile"/></td>
  <td><input type="checkbox" v-model="role.report"/></td>
  <td>
    <a href="#" @click.prevent="createRole">
      <i class="material-icons">save</i>
    </a>
  </td>
</template>
<script>
import { ref } from 'vue'
export default {
  props:[],
  emits:['role-created'],
  setup(props, { emit }){
    const saving = ref(false);
    const role = ref({
      id:-1,
      name:'',
      is_admin:false,
      write_user:false,
      read_user:false,
      write_profile:false,
      read_profile:false,
      report:false
    });
    const createRole = async () => {
      saving.value = true;
      try{
        let resp = await axios.post('/api/roles', role.value)
        role.value = {
          id:'',
          name:'',
          is_admin:false,
          write_user:false,
          read_user:false,
          write_profile:false,
          read_profile:false,
          report:false
        }
        emit('role-created');
      }
      catch(err) 
      {
        console.error(err);
      }
      saving.value = false;
    }
    return {
      role,
      createRole,
      saving,
    }
  }
}
</script>
<style>
</style>
