<template>
<spinner v-if="saving" :showOverlay="true"></spinner>
  <td>{{loc.id}}</td>
  <td><input type="text" v-model="loc_mod.name"/></td>
  <td>
    <select-location-component v-model="loc_mod.parent_loc_id" editMode="true" :exclude="loc_mod" />
    </td>
  <td><input type="text" v-model="loc_mod.orig_id"/></td>
  <td>
    <a href="#" @click.prevent="submit">
      <i class="material-icons">save</i>
    </a>
    &nbsp;
    <a href="#" @click.prevent="toggle">
      <i class="material-icons">cancel</i>
    </a>
  </td>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
export default {
  props:['loc'],
  emits:['location-modified'],
  setup(props,{emit}){
    const saving = ref(false);
    const store = useStore();
    const loc = props.loc;
    const loc_mod = ref({
      id:loc.id,
      name:loc.name,
      orig_id:loc.orig_id,
      parent_loc_id:loc.parent_loc_id
    });
    const toggle = () => loc.edit = !loc.edit;
    const submit = async () => {
      try{
        saving.value = true;
        let resp = await axios.patch('/api/locations/' + loc.id, loc_mod.value);
        loc.name = loc_mod.value.name;
        loc.orig_id = loc_mod.value.orig_id;
        loc.parent_loc_id = loc_mod.value.parent_loc_id;
        await store.dispatch('getLocations');
        saving.value = false;
        toggle();
      }
      catch(err)
      {
        console.error(err);
      }
      saving.value = false;
    }
    return {
      toggle,
      loc_mod,
      submit,
      saving,
    }
  }
}
</script>
<style scoped>
</style>
