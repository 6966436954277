import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/LoginComponent.vue'
import About from '../views/AboutComponent.vue'
import Dashboard from '../views/DashboardComponent.vue'
import Profile from '../views/ProfileComponent.vue'
import AllProfiles from '../views/profiles/ProfilesDashboardComponent.vue'
import Roles from '../views/roles/RolesDashboardComponent.vue'
import Locations from '../views/locations/LocationsDashboardComponent.vue'
import Users from '../views/users/UsersDashboardComponent.vue'
import ShowUser from '../views/users/ShowUserComponent.vue'
import Configs from '../views/AppConfigComponent.vue'
import Reports from '../views/ReportsComponent.vue'
import NotFound from '../views/NotFoundComponent.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: AllProfiles
  },
  {
    path: '/login/:login?',
    name: 'Login',
    component: Login,
    meta: {
      noauth: true,
    },
    props: true,
  },
  {
    path: '/profiles',
    name: 'AllProfiles',
    component: AllProfiles
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile,
    meta: {
      noauth: true,
    },
    props: true,
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles
  },
  {
    path: '/locations',
    name: 'Locations',
    component: Locations,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/configs',
    name: 'Configs',
    component: Configs,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
  },
  {
    path: '/user/:login',
    name: 'ShowUser',
    component: ShowUser,
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  // redirect
  // {
  //    path: 'old-path',
  //    redirect: 'new-path',
  // }

  // catchall 404
  {
    path: '/:catchApp(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      noauth: true,
    }
  },
  {
    path: '/notfound',
    name: 'NotFoundAll',
    component: NotFound,
    meta: {
      noauth: true,
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
const isAuthenticated = function(){
  const sessionData = window.sessionStorage.getItem('vuex')
  if(sessionData)
  {
    let jsSessionData = JSON.parse(sessionData)
    return !!jsSessionData?.userData?.token
  }
  return false
}
router.beforeEach((to,from,next)=>{
  let loggedIn = isAuthenticated();
  if(!to.meta.noauth && !loggedIn)
  {
    let params = {};
    if(to.name != "Dashboard")
    {
      params = {
        nextUrl: to.name,
      };
    }
    next({
      name: "Login",
      params
    });
  }
  else
  {
    if(to.name === "Login" && loggedIn)
    {
      next({
        name: "Dashboard",
      });
    }
    else
    {
      next();
    }
  }
});
export default router
