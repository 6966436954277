<template>
  <dashboard-component>
    <h1>Config Override</h1>
    <pager @first-page="firstPage" @last-page="lastPage" @prev-page="prevPage"
      @next-page="nextPage" :currentPageNo="currentPageNo" 
      :lastPageNo="lastPageNo" :key="pageKey" />
    <table class="table">
      <colgroup>
        <col span="1" style="">
        <col span="1" style="">
        <col span="1" style="min-width: 80px;">
      </colgroup>
      <thead>
        <tr>
          <th>Config</th>
          <th>Value</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <input class="form-control" type="text" v-model="newConfig.config">
          </td>
          <td>
            <textarea class="form-control" rows="4" cols="40" 
              v-model="newConfig.value"></textarea>
          </td>
          <td>
            <a href="#" @click.prevent="saveConfig(newConfig)">
              <i class="material-icons">save</i>
            </a>
          </td>
        </tr>
        <tr v-for="config in configs.data" :key="config.name" >
          <td>
            <input class="form-control" type="text" v-model="config.config">
          </td>
          <td>
            <textarea class="form-control" rows="4" cols="40" 
              v-model="config.value"></textarea>
          </td>
          <td>
            <a href="#" @click.prevent="saveConfig(config)">
              <i class="material-icons">save</i>
            </a>
            <a href="#" @click.prevent="deleteConfig(config)">
              <i class="material-icons text-danger">delete</i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </dashboard-component>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  props:[],
  emits:[],
  setup(props, { emit }){
    const store = useStore()
    const router = useRouter()
    const newConfig = ref({config:"",value:""})
    const configs = ref({})
    const pageKey = ref([0,0])
    const getConfigs = async (page) => {
      try {
        let { data } = await axios.get('/api/configs?page=' + page)
        configs.value = data
        pageKey.value = [configs.value.current_page,configs.value.last_page]
      } catch (err) {
        /* handle error */
        console.error(err)
      }
    }
    const firstPage = () => getConfigs(1)
    const prevPage = () => getConfigs(configs.value.current_page - 1)
    const nextPage = () => getConfigs(configs.value.current_page + 1)
    const lastPage = () => getConfigs(configs.value.last_page)
    const currentPageNo = computed(() => configs.value.current_page)
    const lastPageNo = computed(() => configs.value.last_page)
    const saveConfig = async (config) => {
      try {
        let { data } = await axios.post('/api/configs?page=' + currentPageNo.value, config)
        configs.value = data
        pageKey.value = [configs.value.current_page,configs.value.last_page]
        if(config === newConfig.value)
        {
          newConfig.value = {config:"",value:""}
        }
      } catch (err) {
        /* handle error */
        console.error(err)
      }
    }
    const deleteConfig = async (config) => {
      try {
        let { data } = await axios.delete('/api/configs/' + config.config + '?page=' + currentPageNo.value)
        configs.value = data
        pageKey.value = [configs.value.current_page,configs.value.last_page]
        if(configs.value.current_page > configs.value.last_page)
        {
          getConfigs(configs.value.last_page)
        }
      } catch (err) {
        /* handle error */
        console.error(err)
      }
    }
    onMounted(() => firstPage())
    return {
      newConfig,
      configs,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      currentPageNo,
      lastPageNo,
      pageKey,
      saveConfig,
      deleteConfig,
    }
  }
}
</script>
<style scoped>
</style>
