<template>
  <div class="py-4 d-flex align-items-center" v-if="locationsCount > 1">
    <div class="label-container">Filter:</div>
    <div class="select-container">
    <select class="form-select" :value="modelValue" @change="onChange($event)">
      <option value="-1">Locations</option>
      <option v-for="location in profileLocations" :value="location.id" :key="location.id">{{location.name}}</option>
    </select>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
export default {
  props:['modelValue'],
  emits:['update:modelValue','onChange'],
  setup(props, { emit }){
    const store = useStore()
    const profileLocations = computed(() => store.state.profileLocations)
    const locationsCount = computed(() => Object.keys(profileLocations.value).length ) 
    const onChange = (e) => {
      emit('update:modelValue',e.target.value)
      emit('onChange')
    }
    return {
      profileLocations,
      onChange,
      locationsCount,
    }
  }
}
</script>
<style scoped>
.label-container {
  width:100px;
  font-weight:bold;
}
.select-container {
  max-width:500px; 
}
</style>
