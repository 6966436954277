<template>
<spinner v-if="saving" :showOverlay="true"></spinner>
  <td>New</td>
  <td><input type="text" v-model="loc.name"></td>
  <td><select-location-component v-model="loc.parent_loc_id" editMode="true"/></td>
  <td><input type="text" v-model="loc.orig_id"></td>
  <td>
    <a href="#" @click.prevent="createLocation">
      <i class="material-icons">save</i>
    </a>
  </td>
</template>
<script>
import { ref } from 'vue'
export default {
  props:[],
  emits:['location-created'],
  setup(props, {emit}){
    const saving = ref(false);
    const loc = ref({
      name:'',
      orig_id:null,
      parent_loc_id:null,
    });
    const createLocation = async () => {
      try {
        saving.value = true;
        let {data} = await axios.post('/api/locations', loc.value); 
        saving.value = false;
        loc.value = {
          name:'',
          orig_id:null,
          parent_loc_id:null,
        }
        emit('location-created');
      }
      catch(err) {
        console.error(err);
      }
      saving.value = false;
    }
    return {
      loc,
      createLocation,
      saving,
    }
  }
}
</script>
<style scoped>
</style>
