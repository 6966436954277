<template>
  <dashboard-component>
    <h1>Roles</h1>
    <div v-if="loading">
      <h3>Loading...</h3>
      <spinner v-if="loading" :showOverlay="true"></spinner>
    </div>
    <div v-else>    
    <table class="table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Is Admin</th>
          <th>Read User</th>
          <th>Write User</th>
          <th>Read Profile</th>
          <th>Write Profile</th>
          <th>Report</th>
          <th>Actions</th>
        </tr>
      </thead> 
      <tbody>
        <tr>
          <new-role-component @role-created="getRoles" />
        </tr>
        <tr v-for="role in roles" :key="role.id"> 
          <show-role-component v-if="!role.edit" :role="role" 
            @role-deleted="getRoles"/>
          <edit-role-component v-else :role="role" @role-modified="updated"/>
        </tr>
      </tbody>
    </table>
    </div>
  </dashboard-component>
</template>
<script>
import { ref } from 'vue'
export default {
  setup(){
    const loading = ref(true);
    const roles = ref([]);
    const getRoles = async () => {
      loading.value = true;
      try{
        roles.value = [];
        let { data } = await axios.get('/api/roles')
      
        roles.value = data.map((d) => { d.edit = false; return d;});
      }
      catch(err)
      {
        console.error(err);
      }
      loading.value = false;
    }
    getRoles();

    const updated = () => console.log("Role Has Been Updated")
    return {
      roles,
      updated,
      getRoles,
      loading,
    }
  }
}
</script>
<style>
</style>
