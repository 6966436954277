<template>
<spinner v-if="saving" :showOverlay="true"></spinner>
  <td>{{role.id}}</td>
  <td><input type="text" v-model="role_mod.name"/></td>
  <td><input type="checkbox" v-model="role_mod.is_admin"/></td>
  <td><input type="checkbox" v-model="role_mod.read_user"/></td>
  <td><input type="checkbox" v-model="role_mod.write_user"/></td>
  <td><input type="checkbox" v-model="role_mod.read_profile"/></td>
  <td><input type="checkbox" v-model="role_mod.write_profile"/></td>
  <td><input type="checkbox" v-model="role_mod.report"/></td>
  <td>
    <a href="#" @click.prevent="submit">
      <i class="material-icons">save</i>
    </a>
    &nbsp;
    <a href="#" @click.prevent="toggle">
      <i class="material-icons">cancel</i>
    </a>
  </td>
</template>
<script>
import { ref } from 'vue'
export default {
  props:['role'],
  emits:['role-modified'],
  setup(props, { emit }){
    const saving = ref(false);
    const role = props.role;
    const role_mod = ref({
      id:role.id,
      name:role.name,
      is_admin:role.is_admin,
      write_user:role.write_user,
      read_user:role.read_user,
      write_profile:role.write_profile,
      read_profile:role.read_profile,
      report:role.report
    });
    const toggle = () => role.edit = !role.edit;
    const submit = async () => {
      saving.value = true;
      try{
        let resp = await axios.patch('/api/roles/' + role.id, role_mod.value)
        
        role.name = role_mod.value.name;
        role.is_admin = role_mod.value.is_admin;
        role.write_user = role_mod.value.write_user;
        role.read_user = role_mod.value.read_user;
        role.write_profile = role_mod.value.write_profile;
        role.read_profile = role_mod.value.read_profile;
        role.report = role_mod.value.report;
        toggle();
        emit('role-modified');
      }
      catch(err) 
      {
        console.error(err);
      }
      saving.value = false;
    }
    return {
      toggle,
      role_mod,
      submit,
      saving,
    }
  }
}
</script>
<style>
</style>
