<template>
  <spinner v-if="saving" :showOverlay="true"></spinner>
  <td>{{role.id}}</td>
  <td>{{role.name}}</td>
  <td>
    <span class="material-icons">
      {{role.is_admin ? "check_box" : "check_box_outline_blank" }}
    </span>
  </td>
  <td>
    <span class="material-icons">
      {{role.read_user ? "check_box" : "check_box_outline_blank" }}
    </span>
  </td>
  <td>
    <span class="material-icons">
      {{role.write_user ? "check_box" : "check_box_outline_blank" }}
    </span>
  </td>
  <td>
    <span class="material-icons">
      {{role.read_profile ? "check_box" : "check_box_outline_blank" }}
    </span>
  </td>
  <td>
    <span class="material-icons">
      {{role.write_profile ? "check_box" : "check_box_outline_blank" }}
    </span>
  </td>
  <td>
    <span class="material-icons">
      {{role.report ? "check_box" : "check_box_outline_blank" }}
    </span>
  </td>
  <td>
    <a href="#" @click.prevent="toggle">
      <i class="material-icons">create</i>
    </a>
    &nbsp;
    <a href="#" @click.prevent="deleteRole">
      <i class="material-icons">delete</i>
    </a>
  </td>
</template>
<script>
import { ref } from 'vue'
export default {
  props:['role'],
  emits:['role-deleted'],
  setup(props, {emit}){
    const saving = ref(false);
    const role = props.role;
    const toggle = () => role.edit = !role.edit
    const deleteRole = async () => {
      saving.value = true;
      try {
        let resp = await axios.delete('/api/roles/' + role.id);
        emit('role-deleted');
      }
      catch(err) {
        console.error(err);
      }
      saving.value=false;
    }
    return {
      toggle,
      deleteRole,
      saving,
    }
  }
}
</script>
<style>
</style>
