<template>
  <dashboard-component>
    <div v-if="loading">
      <h3>Loading profile...</h3>
      <spinner v-if="loading" :showOverlay="true"></spinner>
    </div>
    <div v-else>
      <div v-if="profile">
        <h3>Welcome {{ profile.first_name }} {{ profile.last_name }}</h3>
        <ol>
          <li>Please update any incorrect information</li>
          <li>Please click ALL the checkbox in the OK column, even for empty fields rows</li>
          <li>Please provide all information requested, Required fields are marked with *</li>
          <li>Please click Submit when done.  You will be able to come back to make updates</li>
        </ol>
        <table class="table" border="1">
          <thead>
            <tr>
              <th width="20%"></th>
              <th width="75%"></th>
              <th width="5%">OK</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td ><b>*Your First Name: </b></td>
              <td ><input type="text" class="form-control" v-model="profile.first_name" :disabled="confirmFirstName"></td>
              <td ><input type="checkbox" v-model="confirmFirstName"></td>
            </tr>
            <tr>
              <td ><b>*Your Last Name: </b></td>
              <td ><input type="text" class="form-control" v-model="profile.last_name" :disabled="confirmLastName"></td>
              <td ><input type="checkbox" v-model="confirmLastName"></td>
            </tr>
            <tr>
              <td ><b>*Your Email: </b></td>
              <td ><input type="text" class="form-control" v-model="profile.email_updated" :disabled="confirmEmail"></td>
              <td ><input type="checkbox" v-model="confirmEmail"></td>
            </tr>
            <tr>
              <td ><b>*Your Phone: </b></td>
              <td ><input type="text" class="form-control" v-model="profile.phone_updated" :disabled="confirmPhone"></td>
              <td ><input type="checkbox" v-model="confirmPhone"></td>
            </tr>
            <tr>
              <td ><b>*Are you attending an educational institution next year?: </b></td>
              <td >
                <select class="form-control" v-model="profile.attend_college_next_year"  :disabled="confirmNextYear">
                  <option value=null></option>
                  <option value="yes_community">Yes, I am attending a community college</option>
                  <option value="yes_vocational">Yes, I am attending a trade or vocational school</option>
                  <option value="yes_university">Yes, I am attending a four-year college or university</option>
                  <option value="no_taking_gap_year">No, I am planning on taking a gap year</option>
                  <option value="no_not_attending">No, I am not planning to attend an educational institution</option>
                </select>
              </td>
              <td ><input type="checkbox" v-model="confirmNextYear"></td>
            </tr>
            <tr>
              <td ><b>*University/College next year<br>(type "N/A" if not known): </b></td>
              <td >
                <input type="text" class="form-control" v-model="profile.university" :disabled="confirmUniversity" @keyup="getFilteredList" autocomplete="off">
                <div class="panel-footer" v-if="search_data.length">
                  <ul class="form-control">
                    <a href="#" class="list-group-item" v-for="data1 in search_data" :key="data1.name" @click="setUniversityName(data1.name)">{{ data1.name }}</a>
                  </ul>
                </div>
              </td>
              <td ><input type="checkbox" v-model="confirmUniversity"></td>
            </tr>
            <tr>
              <td ><b>*Major<br>(type "N/A" if not known): </b></td>
              <td >
                <input type="text" class="form-control" v-model="profile.major" :disabled="confirmMajor" @keyup="getMajorFilteredList" autocomplete="off">
                <div class="panel-footer" v-if="major_search_data.length">
                  <ul class="form-control">
                    <a href="#" class="list-group-item" v-for="data1 in major_search_data" :key="data1.major" @click="setMajor(data1.major)">{{ data1.major }}</a>
                  </ul>
              </div>
              </td>
              <td ><input type="checkbox" v-model="confirmMajor"></td>
            </tr>
            <tr>
              <td ><b>Institution Email: </b></td>
              <td ><input type="text" class="form-control" v-model="profile.institution_email" :disabled="confirmInstEmail"></td>
              <td ><input type="checkbox" v-model="confirmInstEmail"></td>
            </tr>
            <tr>
              <td ><b>Do you plan to transfer to another university?: </b></td>
              <td >
                <select class="form-control" v-model="profile.plan_to_transfer" :disabled="confirmTransfer">
                  <option value=null></option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                  <option value="maybe">Maybe</option>
                </select>
              </td>
              <td ><input type="checkbox" v-model="confirmTransfer"></td>
            </tr>
            <tr>
              <td ><b>Parent 1's Email: </b></td>
              <td ><input type="text" class="form-control" v-model="profile.mother_email" :disabled="confirmMotherEmail"></td>
              <td ><input type="checkbox" v-model="confirmMotherEmail"></td>
            </tr>
            <tr>
              <td ><b>Parent 1's Phone: </b></td>
              <td ><input type="text" class="form-control" v-model="profile.mother_phone" :disabled="confirmMotherPhone"></td>
              <td ><input type="checkbox" v-model="confirmMotherPhone"></td>
            </tr>
            <tr>
              <td ><b>Parent 2's Email: </b></td>
              <td ><input type="text" class="form-control" v-model="profile.father_email" :disabled="confirmFatherEmail"></td>
              <td ><input type="checkbox" v-model="confirmFatherEmail"></td>
            </tr>
            <tr>
              <td ><b>Parent 2's Phone: </b></td>
              <td ><input type="text" class="form-control" v-model="profile.father_phone" :disabled="confirmFatherPhone"></td>
              <td ><input type="checkbox" v-model="confirmFatherPhone"></td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="offset-sm-2 col-sm-8">
            <span>
              <b>We will add you to the ISN newsletter to keep you informed about future community events for college students.  If you would not like to be added, please type "do not add" below</b>
            </span>&nbsp;
            <input type="text" class="form-control" v-model="profile.add_lo_list" ><br><br>
          </div>
        </div>
        <div class="row">
          <div class="form-group offset-sm-2 col-sm-8">
            <spinner v-if="saving" :showOverlay="true"></spinner>
            <button class="btn btn-danger w-100" 
             @click.prevent="saveProfile" :disabled="disableSave">
              <span>
                {{ !saving ? 'Submit':'Saving...' }}
              </span>&nbsp;
            </button>
          </div>
        </div>
        <div class="row">
          <div class="offset-sm-2 col-sm-8">
            <span :class="saveStatusStyle" v-if="showSaveStatus">
              {{ saveStatusTxt }}
            </span>&nbsp;
          </div>
        </div>
      </div>
      <div v-else>
        <h3>Profie Not Found</h3>
      </div>
    </div>
  </dashboard-component>
</template>
<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
export default {
  props:['id'],
  emits:['changes-submited'],
  setup(props, { emit }){
    const id = computed(() => props.id);
    const loading = ref(true);
    const profile = ref(null);
    const store = useStore();
    const confirmFirstName = ref(false);
    const confirmLastName = ref(false);
    const confirmEmail = ref(false);
    const confirmPhone = ref(false);
    const confirmNextYear = ref(false);
    const confirmTransfer = ref(false);
    const confirmInstEmail = ref(false);
    const confirmUniversity = ref(false);
    const confirmMajor = ref(false);
    const confirmFatherEmail = ref(false);
    const confirmFatherPhone = ref(false);
    const confirmMotherEmail = ref(false);
    const confirmMotherPhone = ref(false);
    const disableSave = computed(() => !confirmFirstName.value || !confirmLastName.value || !confirmEmail.value || !confirmPhone.value 
                                  || !confirmUniversity.value || !confirmMajor.value || !confirmFatherEmail.value 
                                  || !confirmFatherPhone.value || !confirmMotherEmail.value || !confirmMotherPhone.value 
                                  || !confirmNextYear.value || !confirmTransfer.value || !confirmInstEmail.value );
    const showSaveStatus = ref(false);
    const saveStatusTxt = ref("Profile update failed.  Please provide all required information.");
    const saveStatusStyle = ref("text-danger");
    const saving = ref(false);
    const universityList = ref([]);
    const majorList = ref([]);
    const search_data = ref([]);
    const major_search_data = ref([]);

    const getUniveristyList = async() => {
      // universityList.value = [{"name":"University Of Georgia"}, {"name":"Georgia Institute of Technology"}, {"name":"Georgia State University"}];
       try{
        universityList.value = [];
        let { data } = await axios.get('/api/universities');
        universityList.value = data;
      }
      catch(err)
      {
        console.error(err);
      }
    }

    const getMajorList = async() => {
      try
      {
        majorList.value = [];
        let { data } = await axios.get('/api/majors');
        majorList.value = data;
      }
      catch(err)
      {
        console.error(err);
      }
    }

    const getFilteredList = () => {
      if (profile.value.university.length > 0)
      {
        // console.log('^' + profile.value.university.trim().toLowerCase().replace(/\s+/g, '.*') + '$');
        // search_data.value = universityList.value.filter(university => {
        //   return university.name.trim().toLowerCase().indexOf(profile.value.university.trim().toLowerCase()) !== -1;
        // });
        search_data.value = universityList.value.filter(university => {
          return new RegExp('.*' + profile.value.university.trim().toLowerCase().replace(/\s+/g, '.*') + '.*').test(university.name.trim().toLowerCase());
        });
      }
      else
      {
        search_data.value = [];

      }
    }

    const getMajorFilteredList = () => {
      if (profile.value.major.length > 0)
      {
        major_search_data.value = majorList.value.filter(m => {
          return new RegExp('.*' + profile.value.major.trim().toLowerCase().replace(/\s+/g, '.*') + '.*').test(m.major.trim().toLowerCase());
        });
      }
      else
      {
        major_search_data.value = [];

      }
    }

    const setUniversityName = (name) => {
      profile.value.university = name;
      search_data.value = [];
    }

    const setMajor = (major) => {
      profile.value.major = major;
      major_search_data.value = [];
    }

    const getProfile = async (id) => {
      try 
      {
        let { data } = await axios.get('/api/profiles/' + id);
        profile.value = data;
        if(!profile.value.email_updated || profile.value.email_updated.length == 0)
        {
          profile.value.email_updated = profile.value.email_orig;
        }
      }
      catch(err)
      {
        console.error(err);
      }
      loading.value = false;
    }
    const validateInfo = () => {
      if (!validateEmail(profile.value.email_updated))
      {
        saveStatusStyle.value = "text-danger";
        saveStatusTxt.value = "Profile update failed.  Please provide your valid email.";
        showSaveStatus.value = true;
        return false;
      }
      if (!validatePhone(profile.value.phone_updated))
      {
        saveStatusStyle.value = "text-danger";
        saveStatusTxt.value = "Profile update failed.  Please provide your valid phone.";
        showSaveStatus.value = true;
        return false;
      }
      if (profile.value.mother_email && profile.value.mother_email.length > 0 && !validateEmail(profile.value.mother_email))
      {
        saveStatusStyle.value = "text-danger";
        saveStatusTxt.value = "Profile update failed.  Please provide a valid Mother's Email.";
        showSaveStatus.value = true;
        return false;
      }
      if (profile.value.mother_phone && profile.value.mother_phone.length > 0 && !validatePhone(profile.value.mother_phone))
      {
        saveStatusStyle.value = "text-danger";
        saveStatusTxt.value = "Profile update failed.  Please provide a valid Mother's Phone.";
        showSaveStatus.value = true;
        return false;
      }
      if (profile.value.father_email && profile.value.father_email.length > 0 && !validateEmail(profile.value.father_email))
      {
        saveStatusStyle.value = "text-danger";
        saveStatusTxt.value = "Profile update failed.  Please provide a valid Father's Email.";
        showSaveStatus.value = true;
        return false;
      }
      if (profile.value.father_phone && profile.value.father_phone.length > 0 && !validatePhone(profile.value.father_phone))
      {
        saveStatusStyle.value = "text-danger";
        saveStatusTxt.value = "Profile update failed.  Please provide a valid Father's Phone.";
        showSaveStatus.value = true;
        return false;
      }

      return true;
    }

    const saveProfile = async () => {
      if(!validateInfo())
      {
        return;
      }
      saving.value = true;

      let updates = {
        email:profile.value.email_updated,
        phone:profile.value.phone_updated,
        first_name:profile.value.first_name,
        last_name:profile.value.last_name,
        university:profile.value.university,
        major:profile.value.major,
        mother_email:profile.value.mother_email,
        mother_phone:profile.value.mother_phone,
        father_email:profile.value.father_email,
        father_phone:profile.value.father_phone,
        attend_college_next_year:(profile.value.attend_college_next_year=='null' ? '' : profile.value.attend_college_next_year),
        institution_email:profile.value.institution_email,
        plan_to_transfer:(profile.value.plan_to_transfer=='null' ? '' : profile.value.plan_to_transfer),
        add_lo_list:profile.value.add_lo_list,
      }
      try{
        let { data } = await axios.post('/api/profiles/' + id.value + '/save', updates);
        if (data.status_id == 2)
        {
          saveStatusStyle.value = "text-success";
          saveStatusTxt.value = "Profile saved successfully."
        }
        else
        {
          saveStatusStyle.value = "text-danger";
          saveStatusTxt.value = "Profile update failed.  Please provide all required information.";
        }
        emit('changes-submited');
      }
      catch(err)
      {
        console.error(err);
        saveStatusStyle.value = "text-danger";
        saveStatusTxt.value = "Profile update failed.  Please provide all required information.";
      }
      showSaveStatus.value = true;
      saving.value = false;
    }

    getUniveristyList();
    getMajorList();
    getProfile(id.value);

    return {
      profile,
      id,
      loading,
      confirmFirstName,
      confirmLastName,
      confirmEmail,
      confirmPhone,
      confirmNextYear,
      confirmTransfer,
      confirmInstEmail,
      disableSave,
      saveProfile,
      showSaveStatus,
      saveStatusTxt,
      saveStatusStyle,
      saving,
      confirmUniversity,
      confirmMajor,
      confirmFatherEmail,
      confirmFatherPhone,
      confirmMotherEmail,
      confirmMotherPhone,
      search_data,
      major_search_data,
      getFilteredList,
      getMajorFilteredList,
      setUniversityName,
      setMajor,
    }
  }
}
</script>
<style>
  
</style>
