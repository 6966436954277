<template>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <span class="navbar-brand">Profile Review</span>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav me-auto">
      </ul>
      <span class="navbar-text">{{ user.name }}</span>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="#" @click="logout">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data:() => {
    return {
      message: "My Message",
    }
  },
  props:['user'],
  methods: {
    ...mapMutations(['clearUserData']),
    logout(){
      this.clearUserData();
    }
  }
}
</script>
<style>
</style>
