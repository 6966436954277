<template>
  <div class="d-flex flex-row align-items-center justify-content-center" 
    style="top:0;bottom: 0; left: 0; right: 0; position: absolute">
    <div class="container">
      <div class="row">
        <div class="offset-sm-3 col-sm-6">
          <div class="card align-self-center flex-grow-1">
            <div class="card-header text-center" style="font-size: x-large">
              Profile Review
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                  <label for="username">Username</label>
                  <input id="username" v-model="credentials.username" 
                    type="text" class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                  <label for="password">Password</label>
                  <input id="password" v-model="credentials.password" 
                    type="password" class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                  <button v-if="false" class="btn btn-link text-success p-0">
                    Forgot Password?
                  </button>&nbsp;
                </div>
              </div>
              <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                  <spinner v-if="loading" :showOverlay="true"></spinner>
                  <button class="btn btn-success w-100" 
                    v-on:click="authenticate" v-bind:disabled="loading">
                    <span>
                      {{ !loading ? 'Login':'Authenticating...' }}
                    </span>&nbsp;
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="offset-sm-2 col-sm-8">
                  <span class="text-danger" v-if="loginFailed">
                    Login Failed. Please check your credentials.
                  </span>&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

export default {
  props:['login'],
  setup(props){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    // data
    const credentials =  ref({
      username:"",
      password:"",
      login:"",
    });
    const loading = ref(false);
    const loginFailed = ref(false);

    // computed
    const userData = computed(()=> store.state.userData);
    const login_uuid = computed(() => props.login);
    // methods
    const login = (credentials) => store.dispatch('login',credentials);

    const authenticate = ()=>{
      loginFailed.value = false;
      loading.value = true;
      login(credentials.value)
        .then(_ => {
            loading.value = false;
            if(route.params.nextUrl != null)
            {
              router.push({name: route.params.nextUrl});
            }
            else
            {
              router.push({name: "Dashboard"})
            }
          }
        )
        .catch(error => {
          loading.value = false;
          loginFailed.value = true;
          console.error(error);
        }
      );
    } 
    onMounted(() =>{
      if(login_uuid.value && login_uuid.value.length > 0)
      {
        credentials.value.login = login_uuid.value
        authenticate()
      }
    })
    return {
      credentials,
      loading,
      loginFailed,
      userData,
      login,
      authenticate
    }
  }
}
  
</script>
<style scoped>
.btn-success{
  color: #fff;
  font-weight: bold;
}
</style>
